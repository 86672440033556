import { Route, Routes } from 'react-router-dom';
import Links from '../Comoponents/Links';
import Music from '../Comoponents/Music';
import Gallery from '../Comoponents/Gallery';
import Side from '../Comoponents/Side';
import Home from '../Comoponents/Home';

const Routing = () => {
return(
    <Routes>
        <Route exact path='/' element={<Home />}></Route>
        <Route path='/Links' element={<Links />}></Route>
        <Route path='/Music' element={<Music />}></Route>
        <Route path='/Gallery' element={<Gallery />}></Route>
        <Route path='/Side' element={<Side />}></Route>
    </Routes>
)
}

export default Routing;