import React, { Suspense } from "react";
import { Canvas } from "react-three-fiber";
import * as THREE from 'three';
import circleImg from '../Images/star.png'
import { Link } from "react-router-dom";
import StarField from "./StarField";



const Gallery = () => {
    return (
        <div className="gallery">
            <div className='Gallery-heading'><Link className="goback" to={'/'}>{'< Home'}</Link><h1 className="Gallery-header">Gallery</h1></div>
            <h1 className="coming-soon" >COMING SOON</h1>
            <StarField />
        </div>
    )
}

export default Gallery;