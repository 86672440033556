import { useEffect, useState } from 'react';
import MainScene from './MainScene';
import SplashScreen from './SplashScreen'

const Home = () => {
  const [splashScreen, setSplashScreen] = useState();

  useEffect(() => {
    setTimeout(() => {
      setSplashScreen(false)
    }, 2500)
  }, [])

  return (
    <>
      <div className="App">
        {splashScreen ? <SplashScreen /> : <MainScene />}
      </div>
      <div className="App-mobile">
        <div className="App-mobile-body">
          <h1>Aesthetik Side</h1>
          <p>On mobile? come back on PC, or click below:</p>
          <button className='app-mobile-button' onClick={() => { window.location.href = "https://linktr.ee/aesthetikside" }}>Check Out Our link tree</button>
        </div>
      </div>
    </>
  )
}

export default Home;