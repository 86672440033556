import { useEffect, useState } from 'react'
import YTVideo from './YTVideo'
import { Link } from "react-router-dom";
const Music = () => {

    const data = [
        {
            site: 'spotify',
            src: 'https://open.spotify.com/embed/album/1FknZ3RR8qQNUIrU8dadM4?utm_source=generator',
            href: 'https://open.spotify.com/album/1FknZ3RR8qQNUIrU8dadM4?si=PFN3p6c8Q7S4wdlqW7IheA',
            title: "Aesthetik Mountain: Care Package [KG EDITION]"
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1541186926&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/sets/aesthetik-mountain-care-package-kg-edition',
            title: "Aesthetik Mountain: Care Package [KG EDITION]"
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1544323474&color=%234a5b62&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/sets/aesthetik-mountain-care-package-kej-edition-1',
            title: 'Aesthetik Mountain: Care Package [KEJ EDITION]'
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1364575624&color=%234a5b62&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/sets/haf-way-2-madnis',
            title: 'HaF WaY 2 MaDNiS'
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1177178782&color=%234a5b62&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/sets/beats-from-the-bunker',
            title: 'Beats From The Bunker'
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1155516376&color=%234a5b62&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/sets/two-of-a-kind',
            title: 'Two of a Kind'
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1139329147&color=%234a5b62&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/sets/tricks-of-the-trade',
            title: 'Tricks of the Trade'
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1122520459&color=%234a5b62&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/sets/aesthetik-tapes-vol1',
            title: 'Aesthetik Tapes: Vol.1'
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1307406814&color=%234a5b62&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/sets/to-the-moon',
            title: 'To The Moon'
        },
        {
            site: 'soundcloud',
            src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/976024288&color=%234a5b62&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
            href: 'https://soundcloud.com/aesthetik-side/promises',
            title: 'Promises'
        },
    ]

    const [playing, setPlaying] = useState('Select any album cover to play a sample')

    const handleFrameClick = (event) => {
        console.log('hit')
        console.log('Click inside the iframe:', event.target);
    }

    const goToSoundCloud = (event) => {
        window.location.href = "https://soundcloud.com/aesthetik-side";
    }

    return (
        <div className="Music">
            <div className='Music-heading'><Link className="home-button" to={'/'}>{'< Home'}</Link><h1 className="Music-header">Music</h1></div>
            <div className="Music-body">
                <div className='Music-frame'>
                    {
                        data.map((data, index) => {
                            if (data.site === 'soundcloud') {
                                return (
                                    <div className='frames'>
                                        <div className='frame-container'>
                                            <iframe className='frame' title={index} frameborder="no" allow="autoplay" src={data.src}></iframe>
                                            <div><a href={data.href} target="_blank" rel='noreferrer' className='Music-link'>{data.title}</a></div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
            <div className='Music-background'>
            </div>
            <div className='catch-music' onClick={(e) => goToSoundCloud(e)}>
                <span className='Music-playing'></span>
                <span className='Music-playing'>Catch the music on soundcloud...</span>
            </div>
        </div>
    )
}

export default Music;