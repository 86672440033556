import iBeatStars from '../Images/icons/beatstars.png'
import iSoundclick from '../Images/icons/soundclick.jpg'
import iSpotify from '../Images/icons/spotify.png'
import iYoutube from '../Images/icons/youtube.png'
import iSoundcloud from '../Images/icons/soundcloud.png'
import iInstagram from '../Images/icons/instagram.png'
import { Link } from "react-router-dom";


const Links = () => {
    const data = [
        {
            name: 'beatstars',
            link: 'https://www.beatstars.com/aesthetikside/feed',
            iconUri: iBeatStars
        },
        {
            name: 'soundclick',
            link: 'https://www.soundclick.com/artist/default.cfm?bandID=1483217',
            iconUri: iSoundclick
        },
        {
            name: 'spotify',
            link: 'https://open.spotify.com/artist/2BFtASY5ErDy3YUlX9Ujmy?si=9bmzJ_B7SmOWTf4zwsPniw',
            iconUri: iSpotify
        },
        {
            name: 'youtube',
            link: 'https://youtube.com/channel/UCscVUEIERYeXmDUop5nOQbg',
            iconUri: iYoutube
        },
        {
            name: 'instagram',
            link: 'https://www.instagram.com/aesthetik_side/',
            iconUri: iInstagram
        },
        {
            name: 'soundcloud',
            link: 'https://soundcloud.com/aesthetik-side',
            iconUri: iSoundcloud
        }
    ]

    return (
        <div className="Links">
            <Link className="home-button" to={'/'}>{'< Home'}</Link>
            <h1 className="Links-header">LINKS</h1>
            <ul className="Links-list">
                {
                    data.map((data, key) => {
                        return (
                            <li className="Links-link">
                                <span className="Links-link-back"></span>
                                <a href={data.link} target='_blank' rel='noreferrer'><img src={data.iconUri} alt={data.name} width={'80rem'} height={'80rem'}></img></a>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default Links;