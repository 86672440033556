import logo from './logo.svg';
import './App.css';
import './App-mobile.css'
import './ColorPalletes.css'
import { BrowserRouter } from 'react-router-dom';
import Routing from './core/Routing';

function App() {
  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  );
}

export default App;
