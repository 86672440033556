import React from 'react'
import ReactDom from 'react-dom' 
//import getYoutubeVideoData from '../hooks/getYoutubeVideoData';

class YTVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }
    
  componentDidMount() {
    //only works for yt i think :)
    let ytApiKey = process.env.REACT_APP_YOUTUBE;

    fetch("https://www.googleapis.com/youtube/v3/videos?part=snippet&id=" + this.props.videoid + "&key=" + ytApiKey)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.items
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {
    let url = "https://www.youtube.com/embed/" + this.props.videoid;
    
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {

      let videoTitle = items[0].snippet.title
      return (
        <div className="videoHolder">
            {/* <hr className="solid"></hr> */}
            <iframe className="specialframe" width="600" height="400" src={url} title='youtube'></iframe>
        </div>
      );
    }
  }
}


export default YTVideo;