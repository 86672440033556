import { Link } from "react-router-dom";

const Side = () => {
    return (
        <div className="side flex-column">
            <div className='Gallery-heading'><Link className="goback" to={'/'}>{'< Home'}</Link><h2 className='d-flex justify-content-center side-head'>Beats For Sale Playlist</h2></div>
            <div className='d-flex justify-content-center h-100'>
                <iframe width="80%" height="inherit" src="https://www.youtube.com/embed/videoseries?si=Nxo2gpRQHG_0G8QA&amp;list=PLc50M6GBJ5cRd2hQq_PicUeRfK7kMI-Lx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default Side;