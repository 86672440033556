import { useState } from "react";
import { Link } from "react-router-dom";

const MainScene = () => {

    const [sceneHover, setSceneHover] = useState();

    const data = [
        {
            id: 'links',
            innerText: 'AES',
            link: '/Links',
        },
        {
            id: 'music',
            innerText: 'THE',
            link: '/Music',
        },
        {
            id: 'gallery',
            innerText: 'TIK',
            link: '/Gallery',
        },
        {
            id: 'side',
            innerText: 'SIDE',
            link: '/Side',
        }
    ]

    return(
        <div className="main-scene">
            {data.map((data, key) => {
                return(
                    <Link className="scene-group" to={data.link}>
                        {data.innerText}
                    </Link>
                )
            })
            } 
        </div>
    )
}

export default MainScene;